import React from 'react';
import { ActionModal } from 'components/Modal';

export function SuccessModal({ onClose }: { onClose: () => void }) {
  return (
    <ActionModal
      title={'Спасибо.'}
      message={'Заявка успешно отправлена'}
      onClose={onClose}
      actions={[{ name: 'Продолжить', onAction: onClose }]}
    />
  );
}
