import React, { useCallback, useState } from 'react';
import { Button } from 'components/Button';
import { FileInput } from 'components/FileInput';
import { Input, Textarea } from 'components/Input';
import { Modal } from 'components/Modal';
import { Overlay } from 'components/Overlay';
import { Select } from 'components/Select';
import { RatingFeedbackScope, useFeedbackFormQuery, useSendFeedbackMutation } from 'store/graphql';
import { toBase64 } from 'utils/toBase64';
import { SuccessModal, WarningModal } from './_components/ActionModal';
import { Attachment, Attachments } from './_components/Attachments';
import { FormError } from './_components/FormError';
import { InputLabel } from './_components/InputLabel';
import { ModeTabs } from './_components/ModeTabs';
import { attachmentsMaxCount, attachmentsMaxSize } from './_utils/attachmentLimitations';
import { FeedbackMode } from './_utils/FeedbackMode';
import { getScope } from './_utils/getScope';
import s from './FeedbackForm.module.scss';

export interface FeedbackFormProps {
  scopeId: number;
  isRegion: boolean;
  feedbackScope: RatingFeedbackScope;
  close?: () => void;
  refetch?: () => void | Promise<unknown>;
  preselectedIndicatorId?: number;
}

export function FeedbackForm({
  scopeId,
  isRegion,
  feedbackScope,
  refetch,
  close,
  preselectedIndicatorId
}: FeedbackFormProps) {
  const { data } = useFeedbackFormQuery({ variables: { scopeId, isRegion } });
  const regionIndicators = data?.rating.regionRating?.regions[0].indicators;
  const departmentIndicators = data?.rating.departmentRating?.departments[0].indicators;
  const indicators = !!regionIndicators
    ? regionIndicators.map((i) => ({ id: i.indicatorId, name: i.name }))
    : !!departmentIndicators
      ? departmentIndicators.map((i) => ({ id: i.indicatorId, name: i.name }))
      : [];

  const [sendFeedback, { loading, error }] = useSendFeedbackMutation();
  const [mode, setMode] = useState(FeedbackMode.Indicators);
  const [refetchLoading, setRefetchLoading] = useState(false);

  // input state
  const [indicator, setIndicator] = useState<number | null>(preselectedIndicatorId ?? null);
  const [topic, setTopic] = useState('');
  const [comment, setComment] = useState('');
  const [attachmentsMap, setAttachmentsMap] = useState<{
    [name: string]: {
      name: string;
      file: File;
      error?: string | null;
      isDuplicate?: boolean;
    };
  }>({});

  // action modals
  const [warningModal, setWarningModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const scope = getScope(feedbackScope, mode);
  const indicatorId = mode === FeedbackMode.Indicators ? indicator : null;
  const subject = mode === FeedbackMode.Suggestions ? topic : null;
  const attachments = Object.values(attachmentsMap);

  const indicatorChanged = !!indicatorId;
  const topicChanged = !!subject;
  const commentChanged = !!comment;

  const isChanged = indicatorChanged || topicChanged || commentChanged || !!attachments.length;
  const isDone = (indicatorChanged || topicChanged) && commentChanged;

  const handleClose = () => {
    if (isChanged && !warningModal && !successModal) {
      setWarningModal(true);
    } else {
      if (close) close();
    }
  };

  const handleFiles = useCallback(
    (files: FileList) => {
      const map = { ...attachmentsMap };
      let beyondLimit = 0;
      let sizeSum = 0;
      for (const attachment of Object.values(attachmentsMap)) {
        if (!attachment.error) sizeSum += attachment.file.size;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) {
          if (!!map[file.name] && !map[file.name].error) {
            // TODO: убирать все isDuplicate в какой-то момент (при получении новых файлов, например)
            map[file.name] = { ...map[file.name], isDuplicate: true };
          } else if (!map[file.name] && Object.keys(map).length === attachmentsMaxCount) {
            beyondLimit++;
          } else {
            map[file.name] = { name: file.name, file };
            if (file.size > attachmentsMaxSize.bytes - sizeSum) {
              map[file.name].error = 'Превышен допустимый объем файлов';
            } else {
              sizeSum += file.size;
            }
          }
        }
      }

      setAttachmentsMap(map);
    },
    [attachmentsMap]
  );

  const createHandleAttachmentUpload = (name: string) => () =>
    setAttachmentsMap((attachmentsMap) => ({ ...attachmentsMap, [name]: { ...attachmentsMap[name] } }));

  const createHandleAttachmentError = (name: string) => (error: string) =>
    setAttachmentsMap((attachmentsMap) => ({ ...attachmentsMap, [name]: { ...attachmentsMap[name], error } }));

  const createHandleAttachmentRemove = (name: string) => () =>
    setAttachmentsMap((prevAttachmentsMap) => {
      const attachmentsMap = { ...prevAttachmentsMap };
      delete attachmentsMap[name];
      return attachmentsMap;
    });

  const handleSubmit = async () => {
    const attachmentBlobs: {
      content: string;
      fileName: string;
    }[] = [];

    for (const a of attachments) {
      const blob = await toBase64(a.file);
      if (typeof blob === 'string') {
        attachmentBlobs.push({
          fileName: a.name,
          content: String(blob)
        });
      }
    }
    sendFeedback({
      variables: {
        input: {
          scope,
          scopeId,
          indicatorId,
          subject,
          commentary: comment,
          attachments: attachmentBlobs
        }
      }
    })
      .then(async () => {
        setSuccessModal(true);
        if (refetch) {
          try {
            setRefetchLoading(true);
            await refetch();
            setRefetchLoading(false);
          } catch (e) {
            setRefetchLoading(false);
          }
        }
      })
      .catch(() => {});
  };
  return (
    <Overlay onClick={handleClose}>
      {successModal ? (
        <SuccessModal onClose={handleClose} />
      ) : warningModal ? (
        <WarningModal onClose={handleClose} onBack={() => setWarningModal(false)} />
      ) : (
        <Modal title={'Форма обратной связи'} onClose={handleClose}>
          <ModeTabs mode={mode} changeMode={setMode} indicatorsName={'Показатели'} />

          {mode === FeedbackMode.Indicators ? (
            <InputLabel title={'Показатель'}>
              <Select<number>
                placeholder={'Выберите показатель из списка'}
                disabled={!indicators.length}
                options={indicators.map(({ id, name }) => ({ label: name, value: id }))}
                value={indicator}
                onChange={setIndicator}
              />
            </InputLabel>
          ) : (
            <InputLabel title={'Тема'}>
              <Input placeholder={'Тема предложения или заявки'} value={topic} onChange={setTopic} />
            </InputLabel>
          )}

          <InputLabel title={'Комментарий'}>
            <Textarea placeholder={'Общий произвольный комментарий'} value={comment} onChange={setComment} />
          </InputLabel>

          <div className={s.FeedbackForm__group}>
            {!!attachments.length && (
              <Attachments>
                {attachments.map((a) => (
                  <Attachment
                    key={a.name}
                    name={a.name}
                    file={a.file}
                    error={a.error}
                    isDuplicate={a.isDuplicate}
                    onUpload={createHandleAttachmentUpload(a.name)}
                    onError={createHandleAttachmentError(a.name)}
                    onRemove={createHandleAttachmentRemove(a.name)}
                  />
                ))}
              </Attachments>
            )}

            <FileInput
              multiple
              onChange={handleFiles}
              description={`Загрузите до ${attachmentsMaxCount} файлов объемом не более ${attachmentsMaxSize.mb} MB в формате DOC, DOCX, PDF, XLSX, XLSX.`}
            />
          </div>

          <div className={s.FeedbackForm__group}>
            <Button
              style={{ alignSelf: 'flex-end', minWidth: 114 }}
              loading={loading || refetchLoading}
              disabled={!isDone}
              onClick={handleSubmit}>
              Отправить
            </Button>

            {!!error && <FormError />}
          </div>
        </Modal>
      )}
    </Overlay>
  );
}
