import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { FileIcon, FileIconSize } from 'components/FileIcon';
import { Spinner, SpinnerKind } from 'components/Spinner';
import { Tooltip, TooltipArrowPosition } from 'components/Tooltip';
import { useFilePresignedUrlLazyQuery } from 'store/graphql';
import s from './FeedbackFile.module.scss';

export type FeedbackFileProps = {
  fileName: string;
  fileFormat?: string;
  fileIconSize: FileIconSize;
  id: string;
};

export function FeedbackFile({ fileName, fileFormat, fileIconSize, id }: FeedbackFileProps) {
  const [overLoading, setOverLoading] = useState(false);

  const [getDownloadUrl, { loading }] = useFilePresignedUrlLazyQuery({
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    setTimeout(() => {
      setOverLoading(false);
    }, 3000);
  }, [loading]);

  const onDownload = async () => {
    setOverLoading(true);
    if (loading) return;

    const { data } = await getDownloadUrl({ variables: { fileUUID: id } });
    const dwnldUrl = data?.ratingFeedbackFilePresignedUrl;
    if (!dwnldUrl) {
      console.error('Ошибка запроса URL на скачивание файла');
      return;
    }

    const linkNode = document.createElement('a');
    linkNode.href = dwnldUrl;
    linkNode.click();
  };

  return (
    <Tooltip
      tooltipStyle={{ marginLeft: 26 + 12 - 11 }}
      arrowPosition={TooltipArrowPosition.BottomLeft}
      wrapperClassName={s.FeedbackFile__tooltip}
      content={fileName}
      children={
        <div
          className={clsx(
            s.FeedbackFile,
            fileIconSize === FileIconSize.M ? s.FeedbackFile__sizeIcon_m : s.FeedbackFile__sizeIcon_s
          )}
          onClick={onDownload}>
          {overLoading ? (
            <div className={s.FeedbackFile__spinner}>
              <Spinner kind={SpinnerKind.Inline} />
            </div>
          ) : (
            <FileIcon fileName={fileFormat} fileIconSize={fileIconSize} />
          )}
          <div className={s.FeedbackFile__info}>
            <div className={s.FeedbackFile__name}>{fileName}</div>
          </div>
        </div>
      }
    />
  );
}
