import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import s from './RoundIconButton.module.scss';

export interface RoundIconButtonProps {
  disabled?: boolean;
  to?: string;
  stateTo?: object;
  preventScrollReset?: boolean;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon: React.ElementType;
  className?: string;
  style?: React.CSSProperties;
}

export function RoundIconButton({
  disabled,
  to,
  stateTo,
  preventScrollReset,
  href,
  onClick,
  icon: Icon,
  className: customClassName,
  style: customStyle
}: RoundIconButtonProps) {
  const style = customStyle;
  const className = clsx(s.RoundIconButton, disabled && s.RoundIconButton_disabled, customClassName);
  const content = <Icon />;

  if (to && !disabled) {
    return (
      <Link to={to} state={stateTo} style={style} className={className} preventScrollReset={preventScrollReset}>
        {content}
      </Link>
    );
  } else if (href && !disabled) {
    return (
      <a href={href} target={'_blank'} rel={'noreferrer'} style={style} className={className}>
        {content}
      </a>
    );
  } else {
    const handleClick = !disabled ? onClick : undefined;
    return (
      <button disabled={disabled} onClick={handleClick} style={style} className={className}>
        {content}
      </button>
    );
  }
}
