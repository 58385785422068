import React, { useState } from 'react';
import clsx from 'clsx';
import { useClickOutside } from '@digital-gov/ui-utils';
import { DropdownArrow } from 'components/DropdownArrow';
import { DropdownList, DropdownListItem } from 'components/DropdownList';
import { Dismiss } from 'components/Icon';
import s from './Select.module.scss';

export interface SelectProps<V> {
  value?: V | null;
  onChange?: (value: V) => void;
  canReset?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  largeItems?: boolean;
  options: {
    value: V;
    label?: string;
  }[];
}

export function Select<V = string>({
  value,
  onChange,
  canReset,
  placeholder = 'Выберите значение',
  disabled,
  className,
  largeItems,
  options
}: SelectProps<V>) {
  const [dropdown, setDropdown] = useState(false);
  const [select, setSelect] = useState<HTMLDivElement | null>(null);
  const current = options.find((o) => o.value === value);
  const currentLabel = current?.label ?? (current?.value && `${current?.value}`) ?? placeholder;

  useClickOutside(select, () => setDropdown(false));

  return (
    <div className={clsx(s.Select, className)} ref={setSelect}>
      <div
        onClick={() => !disabled && setDropdown((dropdown) => !dropdown)}
        className={clsx(
          s.Select__input,
          currentLabel === placeholder && s.Select__input_placeholder,
          dropdown && s.Select__input_open,
          disabled && s.Select__input_disabled
        )}>
        {currentLabel}
        <DropdownArrow isOpen={dropdown} />
      </div>

      {dropdown && (
        // TODO: возможно, добавить scrollIntoView для выбранного значения
        <DropdownList className={s.Select__dropdown}>
          <div className={s.Select__dropdownItems}>
            {options.map((o) => (
              <DropdownListItem
                key={`${o.value}`}
                selected={value === o.value}
                isLarge={largeItems}
                onClick={() => {
                  setDropdown(false);
                  onChange?.(o.value);
                }}>
                {o.label ?? `${o.value}`}
                {canReset && value === o.value && <Dismiss style={{ marginLeft: 'auto', color: '#787a92' }} />}
              </DropdownListItem>
            ))}
          </div>
        </DropdownList>
      )}
    </div>
  );
}
