import { useState } from 'react';

export type FeedbackState = {
  history: boolean;
  form: boolean;
  card: boolean;
  feedbackId: number | string | null;
};

export function useFeedback() {
  const [feedback, setFeedback] = useState<FeedbackState>({
    history: false,
    form: false,
    card: false,
    feedbackId: null
  });

  // открыть модалку истории ФОС - остальное закрыть
  const openHistory = () => {
    setFeedback({
      history: true,
      form: false,
      card: false,
      feedbackId: null
    });
  };

  // открыть карточку конкретной ФОС - остальное закрыть
  const openCard = (feedbackId: number | string) => {
    setFeedback({
      history: false,
      form: false,
      card: true,
      feedbackId
    });
  };

  // закрыть карточку конкретной ФОС (вместе со всем)
  const closeAll = () => {
    setFeedback({
      history: false,
      form: false,
      card: false,
      feedbackId: null
    });
  };

  // открыть форму отправки ФОС
  const openForm = () => {
    setFeedback({
      history: false,
      form: true,
      card: false,
      feedbackId: null
    });
  };

  return {
    feedback,
    openHistory,
    openForm,
    openCard,
    closeAll
  };
}
