import React from 'react';
import { ActionModal } from 'components/Modal';

export function WarningModal({ onClose, onBack }: { onClose: () => void; onBack: () => void }) {
  return (
    <ActionModal
      title={'Внимание!'}
      message={'Вы уверены, что хотите закрыть форму обратной связи? Данные не будут сохранены.'}
      onClose={onClose}
      actions={[
        { name: 'Закрыть форму', onAction: onClose },
        { name: 'Назад', onAction: onBack }
      ]}
    />
  );
}
