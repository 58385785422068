import React from 'react';
import {
  BmpSizeS,
  BmpSizeM,
  DocSizeS,
  DocSizeM,
  DocxSizeS,
  DocxSizeM,
  GifSizeS,
  GifSizeM,
  JpegSizeS,
  JpegSizeM,
  JpgSizeS,
  JpgSizeM,
  PdfSizeS,
  PdfSizeM,
  PngSizeS,
  PngSizeM,
  PptSizeS,
  PptSizeM,
  PptxSizeS,
  PptxSizeM,
  RarSizeS,
  RarSizeM,
  TxtSizeS,
  TxtSizeM,
  UnknownSizeS,
  UnknownSizeM,
  WebpSizeS,
  WebpSizeM,
  XlsSizeS,
  XlsSizeM,
  XlsxSizeS,
  XlsxSizeM,
  ZipSizeS,
  ZipSizeM
} from '.';

export type IconType = {
  S: React.ElementType;
  M: React.ElementType;
};

export type FileIconMapperType = Record<string, IconType>;

export const FileIconMapper: FileIconMapperType = {
  bmp: { S: BmpSizeS, M: BmpSizeM },
  doc: { S: DocSizeS, M: DocSizeM },
  docx: { S: DocxSizeS, M: DocxSizeM },
  gif: { S: GifSizeS, M: GifSizeM },
  jpeg: { S: JpegSizeS, M: JpegSizeM },
  jpg: { S: JpgSizeS, M: JpgSizeM },
  pdf: { S: PdfSizeS, M: PdfSizeM },
  png: { S: PngSizeS, M: PngSizeM },
  ppt: { S: PptSizeS, M: PptSizeM },
  pptx: { S: PptxSizeS, M: PptxSizeM },
  rar: { S: RarSizeS, M: RarSizeM },
  txt: { S: TxtSizeS, M: TxtSizeM },
  unknown: { S: UnknownSizeS, M: UnknownSizeM },
  webp: { S: WebpSizeS, M: WebpSizeM },
  xls: { S: XlsSizeS, M: XlsSizeM },
  xlsx: { S: XlsxSizeS, M: XlsxSizeM },
  zip: { S: ZipSizeS, M: ZipSizeM }
};
