import React, { PropsWithChildren, useLayoutEffect, useMemo, useState } from 'react';
import { from, useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth } from '@digital-gov/auth-apollo-store';
import { useProfileQuery } from 'store/graphql';
import { ProfileContext } from './ProfileContext';

export const ProfileProvider = ({ children }: PropsWithChildren) => {
  const apolloClient = useApolloClient();

  const authContext = useAuth();
  const isLoggedIn = authContext.isLoggedIn();
  const { loading: queryLoading, error, data } = useProfileQuery({ skip: !isLoggedIn });

  const [departmentPeriod, setDepartmentPeriod] = useState(Number(localStorage.getItem('departmentPeriod')) || null);
  const [regionPeriod, setRegionPeriod] = useState(Number(localStorage.getItem('regionPeriod')) || null);
  const [loading, setLoading] = useState(false);

  const profile = data?.rating.profile;

  const departmentPeriodPublished = profile?.departmentPeriods.find(({ published }) => published);
  const regionPeriodPublished = profile?.regionPeriods.find(({ published }) => published);

  // текущий выбранный период ФРЦТ (меняется за счёт departmentPeriod и setDepartmentPeriod)
  const currentDepartmentPeriod =
    profile?.departmentPeriods.find((dp) => dp.periodId === departmentPeriod) ||
    (departmentPeriodPublished ?? profile?.departmentPeriods[0] ?? null);
  // текущий выбранный период РРЦТ (меняется за счёт regionPeriod и setRegionPeriod)
  const currentRegionPeriod =
    profile?.regionPeriods.find((rp) => rp.periodId === regionPeriod) ||
    (regionPeriodPublished ?? profile?.regionPeriods[0] ?? null);

  const isCurrentDepartmentPeriod =
    !profile?.allowedToChangePeriod || departmentPeriodPublished?.periodId === currentDepartmentPeriod?.periodId;

  const isCurrentRegionPeriod =
    !profile?.allowedToChangePeriod || regionPeriodPublished?.periodId === currentRegionPeriod?.periodId;

  useLayoutEffect(() => {
    const apolloLink = apolloClient.link;
    if (currentDepartmentPeriod?.periodId || currentRegionPeriod?.periodId) {
      const periodHeaders = {
        'X-Rating-Department-Period': currentDepartmentPeriod?.periodId,
        'X-Rating-Region-Period': currentRegionPeriod?.periodId
      };

      const periodLink = setContext((_, { headers }) => ({
        headers: { ...headers, ...periodHeaders }
      }));

      apolloClient.setLink(from([periodLink, apolloLink]));
    }
    return () => apolloClient.setLink(apolloLink);
  }, [apolloClient, currentDepartmentPeriod?.periodId, currentRegionPeriod?.periodId]);

  useLayoutEffect(() => {
    if ((departmentPeriod && !!currentDepartmentPeriod) || (regionPeriod && !!currentRegionPeriod)) {
      if (departmentPeriod) localStorage.setItem('departmentPeriod', departmentPeriod.toString());
      if (regionPeriod) localStorage.setItem('regionPeriod', regionPeriod.toString());

      setLoading(true);
      apolloClient
        .resetStore()
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient, departmentPeriod, regionPeriod]);

  const contextValue = useMemo(() => {
    return {
      profileLoading: loading || queryLoading,
      profileError: error,
      profile,
      isObserver: profile?.isObserver,
      isRRCT: !!profile?.regionId && !profile?.isObserver,
      regionId: profile?.regionId,
      regionName: profile?.regionName,
      isFRCT: !!profile?.departmentId && !profile?.isObserver,
      departmentId: profile?.departmentId,
      departmentName: profile?.departmentName,
      departmentPublished: profile?.departmentPublished ?? false,
      allowedToChangePeriod: profile?.allowedToChangePeriod ?? false,
      allowedToComment: profile?.allowedToComment ?? false,
      allowedToReadFeedback: profile?.allowedToReadFeedback ?? false,
      allowedToFeedback: profile?.allowedToFeedback ?? false,
      isCurrentDepartmentPeriod,
      departmentPeriod: currentDepartmentPeriod?.periodId ?? null,
      setDepartmentPeriod,
      isCurrentRegionPeriod,
      regionPeriod: currentRegionPeriod?.periodId ?? null,
      setRegionPeriod
    };
  }, [
    loading,
    queryLoading,
    error,
    profile,
    isCurrentDepartmentPeriod,
    currentDepartmentPeriod?.periodId,
    isCurrentRegionPeriod,
    currentRegionPeriod?.periodId
  ]);

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};
