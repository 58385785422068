import React from 'react';
import { Link } from 'react-router-dom';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import s from './FeedbackListItem.module.scss';

export interface FeedbackListItemProps {
  title?: string;
  author?: string;
  date?: Date | string;
  onClick: () => void;
}

export function FeedbackListItem({ title = 'Тема не указана', date, onClick, ...props }: FeedbackListItemProps) {
  // учитывает возможность пустой строки
  const author = props.author || 'Автор неизвестен';
  return (
    <div className={s.FeedbackListItem} onClick={() => onClick()}>
      <div className={s.FeedbackListItem__title}>{title}</div>
      <div className={s.FeedbackListItem__info}>
        {author}
        {date && (
          <>
            {' '}
            | <span className={s.FeedbackListItem__date}>{formatDate(date, DATE_SHORT_YEAR)}</span>
          </>
        )}
      </div>
    </div>
  );
}
