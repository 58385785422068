import React from 'react';
import clsx from 'clsx';
import { Link, NavLink } from 'react-router-dom';
import s from './Tab.module.scss';

export interface TabProps {
  children?: string;
  to?: string;
  navTo?: string;
  onClick?: () => void;
  active?: boolean;
  className?: string;
  disabled?: boolean;
}

export function Tab({ children, to, navTo, onClick, active, className, disabled }: TabProps) {
  const tabClassName = clsx(s.Tab, active && s.Tab_active, className, disabled && s.Tab_disabled);

  if (navTo && !disabled) {
    return (
      <NavLink to={navTo} className={({ isActive }) => clsx(tabClassName, isActive && s.Tab_active)}>
        {children}
      </NavLink>
    );
  } else if (to && !disabled) {
    return (
      <Link to={to} className={tabClassName}>
        {children}
      </Link>
    );
  } else {
    const handleClick = () => !disabled && onClick?.();
    return (
      <button onClick={handleClick} className={tabClassName} disabled={disabled}>
        {children}
      </button>
    );
  }
}
