import React from 'react';
import clsx from 'clsx';
import s from './Tabs.module.scss';

export function Tabs({
  children,
  className,
  style
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}) {
  return (
    <nav style={style} className={clsx(s.Tabs, className)}>
      {children}
    </nav>
  );
}
