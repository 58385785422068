import React, { useLayoutEffect, useState } from 'react';
import { Modal, ModalError } from 'components/Modal';
import { Overlay } from 'components/Overlay';
import { Plug, PlugVariant } from 'components/Plug';
import { Tab, Tabs } from 'components/Tabs';
import { DepartmentFeedbackType, RegionFeedbackType } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { FeedbackList, FeedbackListItem } from './_components/FeedbackList';

enum HistoryFilter {
  All = 'all',
  My = 'my'
}

export interface FeedbackHistoryProps {
  scopeId: number; // id ФОИВ-а или РОИВ-а
  data?: DepartmentFeedbackType[] | RegionFeedbackType[] | null;
  close: () => void;
  selectFeedback: (feedbackId: number | string) => void;
}

export function FeedbackHistory({ data, close, selectFeedback }: FeedbackHistoryProps) {
  const [historyFilter, setHistoryFilter] = useState<HistoryFilter | null>(null);

  const { profile } = useProfile();
  const fullName = profile?.fullName;

  const FeedbackDataAll = data;

  const FeedbackDataMy = FeedbackDataAll?.filter(
    (fd) => fd.publisher === fullName && fullName != null && fullName !== ''
  );

  useLayoutEffect(() => {
    if (!!FeedbackDataMy && !historyFilter) {
      if (!FeedbackDataMy.length) {
        setHistoryFilter(HistoryFilter.All);
      } else {
        setHistoryFilter(HistoryFilter.My);
      }
    }
  }, [historyFilter, FeedbackDataMy]);

  return (
    <Overlay onClick={close}>
      <Modal title={'История заявок'} onClose={close}>
        <React.Fragment>
          <Tabs>
            <Tab
              onClick={() => setHistoryFilter(HistoryFilter.My)}
              active={historyFilter === HistoryFilter.My}
              disabled={!FeedbackDataMy?.length}>
              Мои
            </Tab>
            <Tab onClick={() => setHistoryFilter(HistoryFilter.All)} active={historyFilter === HistoryFilter.All}>
              Все
            </Tab>
          </Tabs>

          {!FeedbackDataAll?.length ? (
            <Plug description={'Не подано ни одной заявки'} variant={PlugVariant.Empty} />
          ) : (
            <FeedbackList>
              {historyFilter === HistoryFilter.My
                ? FeedbackDataMy?.map((item, index) => (
                    <FeedbackListItem
                      key={index}
                      onClick={() => selectFeedback(item.feedbackId)}
                      title={
                        item.subject
                          ? item.subject
                          : item.indicatorName
                            ? `Показатель «${item.indicatorName}»`
                            : undefined
                      }
                      author={item.publisher}
                      date={item.publishDate}
                    />
                  ))
                : FeedbackDataAll?.map((item, index) => (
                    <FeedbackListItem
                      key={index}
                      onClick={() => selectFeedback(item.feedbackId)}
                      title={
                        item.subject
                          ? item.subject
                          : item.indicatorName
                            ? `Показатель «${item.indicatorName}»`
                            : undefined
                      }
                      author={item.publisher}
                      date={item.publishDate}
                    />
                  ))}
            </FeedbackList>
          )}
        </React.Fragment>
      </Modal>
    </Overlay>
  );
}
