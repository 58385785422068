import React from 'react';
import { ButtonLink, RoundIconButton } from 'components/Button';
import { Notification } from 'components/Icon';
import { Tooltip, TooltipArrowPosition } from 'components/Tooltip';
import s from './FeedbackControls.module.scss';

export function FeedbackControls({
  hasFeedback,
  allowedToRead,
  allowedToSend,
  highlightBadge,
  openForm,
  openHistory
}: {
  hasFeedback?: boolean;
  allowedToRead?: boolean;
  allowedToSend?: boolean;
  highlightBadge?: boolean;
  openForm: () => void;
  openHistory: () => void;
}) {
  const tooltipContent = hasFeedback ? 'История заявок' : 'Не подано ни одной заявки';
  const tooltipStyle = { marginRight: -1, marginBottom: 1 };

  return (
    <React.Fragment>
      <div style={{ display: 'flex', gap: 12 }}>
        {allowedToSend && (
          <ButtonLink onClick={openForm} preventScrollReset>
            Форма обратной связи
          </ButtonLink>
        )}
        {allowedToRead && (
          <Tooltip
            content={tooltipContent}
            tooltipStyle={tooltipStyle}
            arrowPosition={TooltipArrowPosition.BottomRight}>
            <div className={s.FeedbackControls__history}>
              <RoundIconButton
                style={{ backgroundColor: '#1f1c2f', color: '#d2d3dd' }}
                disabled={!hasFeedback}
                icon={Notification}
                onClick={openHistory}
              />
              {highlightBadge && hasFeedback && <div className={s.FeedbackControls__indicator} />}
            </div>
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
}
