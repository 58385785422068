import React, { useState } from 'react';
import clsx from 'clsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { PageError } from 'components/PageError';
import { PageLoader } from 'components/PageLoader';
import { GraphIcon, ListIcon, TabBar } from 'components/TabBar';
import { PageHeader } from 'componentsL/PageHeader';
import { DownloadsBlock } from 'features/DownloadsBlock';
import { FeedbackCard, FeedbackHistory } from 'features/Feedback';
import { FeedbackControls } from 'features/FeedbackControls';
import { FeedbackForm } from 'features/FeedbackForm';
import { IndicatorsTable } from 'features/IndicatorsTable';
import { StatisticsBlock } from 'features/StatisticsBlock';
import { useFeedback } from 'hooks/useFeedback';
import { basename } from 'store/config';
import {
  DepartmentIndicatorType,
  ExcelDataSourceEnum,
  RatingCommentScopeEnum,
  RatingFeedbackScope,
  useDepartmentPageQuery
} from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { departmentEfficiencyGroups } from 'utils/ratingGroups';
import Card from './Card';
import s from './styled.module.scss';

type ViewMode = 'graph' | 'table';

export function DepartmentPage() {
  const { departmentId } = useParams();
  const { allowedToFeedback, allowedToReadFeedback } = useProfile();
  const [searchParams] = useSearchParams();
  const mode = (searchParams.get('mode') || 'table') as ViewMode;

  const { loading, error, data, refetch } = useDepartmentPageQuery({
    variables: {
      ids: [Number(departmentId)]
    }
  });

  const { feedback, openCard, openHistory, openForm, closeAll } = useFeedback();

  const department = data?.rating?.departmentRating.departments?.[0];

  if (loading) return <PageLoader />;
  if (!!error || !department) return <PageError />;

  const indicatorsWithoutGroup = department.indicators.filter(({ groupField }) => !groupField);

  const indicatorsByEpgu = department.indicators.filter(({ groupField }) => groupField === 'epgu');

  const title =
    department.departmentName.length <= 32
      ? `Оперативный рейтинг ФРЦТ ${department.departmentName}`
      : `Оперативный рейтинг ФРЦТ`;
  const titleSecondRow = department.departmentName.length > 32 ? department.departmentName : undefined;

  const hasFeedback = (department.feedback?.length ?? 0) > 0;
  return (
    <div>
      <PageHeader
        backTo={'/'}
        title={title}
        titleSecondRow={titleSecondRow}
        slotRight={
          <div className={clsx(s.Raw, s.Buttons)}>
            <div className={s.Buttons__group}>
              <DownloadsBlock
                links={[
                  {
                    text: 'Методика',
                    href: encodeURI(`${basename}/uploads/Методика_оперативного_рейтинга_ФРЦТ_2024.pdf`)
                  }
                ]}
                excel={{
                  dataSource: ExcelDataSourceEnum.Department,
                  entityId: department.departmentId.toString(),
                  text: 'Расчет'
                }}
              />
            </div>

            {(allowedToFeedback || allowedToReadFeedback) && (
              <div className={s.Buttons__group}>
                <FeedbackControls
                  allowedToRead={allowedToReadFeedback}
                  allowedToSend={allowedToFeedback}
                  hasFeedback={hasFeedback}
                  openForm={openForm}
                  openHistory={openHistory}
                />
              </div>
            )}

            <div className={s.Buttons__group}>
              <TabBar
                items={[
                  { icon: GraphIcon, to: `/department/${departmentId}?mode=graph`, active: mode === 'graph' },
                  { icon: ListIcon, to: `/department/${departmentId}?mode=table`, active: mode === 'table' }
                ]}
              />
            </div>
          </div>
        }
      />

      {departmentId && (
        <StatisticsBlock
          efficiencyGroup={department.ratingGroup}
          efficiencyGroupText={departmentEfficiencyGroups[department.ratingGroup].text}
          degree={department.degree}
          pointsTotal={department.pointsTotal}
          pointsMax={department.pointsMax}
          pointsDynamic={department.overallDynamic}
          lastPeriodDate={department.previousPeriodDate}
          indicatorsCount={department.indicators.length}
          frct={department.frct[0]?.fullName ?? null}
          viceChairman={department.viceChairmans[0]?.fullName ?? null}
          commentsControl={{
            scope: RatingCommentScopeEnum.Department,
            scopeId: department.departmentId,
            scopeName: department.departmentName,
            comments: department.recommendations?.entries,
            draft: department.recommendations?.draft,
            refetch: refetch
          }}
        />
      )}

      {allowedToReadFeedback && feedback.history && (
        <FeedbackHistory
          scopeId={department.departmentId}
          data={department.feedback}
          close={closeAll}
          selectFeedback={openCard}
        />
      )}

      {allowedToReadFeedback && feedback.card && feedback.feedbackId && (
        <FeedbackCard
          data={department.feedback?.find((d) => d.feedbackId === feedback.feedbackId)}
          isRegion={false}
          close={closeAll}
          backToHistory={openHistory}
        />
      )}

      {allowedToFeedback && feedback.form && (
        <FeedbackForm
          scopeId={department.departmentId}
          isRegion={false}
          feedbackScope={RatingFeedbackScope.Department}
          close={closeAll}
          refetch={refetch}
        />
      )}

      {mode === 'table' && (
        <div className={s.TableWrap}>
          <IndicatorsTable
            rows={department.indicators.map((value) => ({
              indicatorName: value.name,
              value: value.pointsTotal,
              valueDynamic: value.dynamic,
              valueMax: value.pointsMax,
              successMark: value.successMark,
              alertMark: value.alertMark,
              indicatorProgressGroup: value.progressGroup,
              linkTo: `/department/${departmentId}/indicator/${value.indicatorId}`,
              availableForDetails: value.availableForDetails,
              hasFeedback: value.haveFeedback,
              hasRecommendations: value.haveRecommendations
            }))}
          />
        </div>
      )}

      {mode === 'graph' && (
        <>
          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(0, 3).map((indicator, i) => (
              <div className={s.Raw_item} key={i}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card
                    linkTo={`indicator/${indicator.indicatorId}`}
                    indicator={indicator as DepartmentIndicatorType}
                  />
                </div>
              </div>
            ))}
          </div>

          {indicatorsByEpgu.length > 0 && (
            <div className={`${s.Paper} ${s.Min_height}`}>
              <h4 className={s.GroupName}>{'ЕПГУ'}</h4>
              <div className={s.Raw}>
                {indicatorsByEpgu.map(({ indicatorId, ...indicator }: any, i) => (
                  <div className={s.Raw_item} key={i}>
                    <Card linkTo={`indicator/${indicatorId}`} indicator={indicator} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(3, indicatorsWithoutGroup.length).map((indicator, i) => (
              <div className={s.Raw_item} key={i}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card
                    linkTo={`indicator/${indicator.indicatorId}`}
                    indicator={indicator as DepartmentIndicatorType}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
