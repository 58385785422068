import React, { useMemo, useState } from 'react';
import { DATE_FULL_DAY_SHORT, formatDate } from '@digital-gov/ui-utils';
import { PageError } from 'componentsL/ErrorMessage';
import { Loader } from 'componentsL/Loader';
import { PageHeader } from 'componentsL/PageHeader';
import { ScopeSwitch } from 'componentsL/ScopeSwitch';
import { ScopeEnum } from 'componentsL/ScopeSwitch/ScopeSwitch';
import { useDepartments_MviQuery } from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { ChangeModeBlock } from '../_components/ChangeModeBlock';
import { GaugeCardsList } from '../_components/GaugeCardsList/GaugeCardsList';
import s from '../mvi.module.scss';
import { MviTabs, MviType, ViewMode } from '../types';

export function DepartmentPageMvi() {
  const { isObserver, isFRCT, isRRCT } = useProfile();

  const [mode, setMode] = useState<ViewMode>('carousel');

  const { data, loading, error } = useDepartments_MviQuery({
    variables: {}
  });

  const rating = data?.rating;
  const lastUpdateDate = rating?.departmentRating.lastUpdateDate ?? undefined;

  const list = useMemo(() => {
    if (!data?.rating.departmentsMvi || data.rating.departmentsMvi.length === 0) {
      return [];
    }
    return data.rating.departmentsMvi.map((el) => ({
      averageDegree: Math.round(el.averageDegree),
      maxShare: el.departmentsMaxShare,
      minShare: el.departmentsMinShare,
      quantity: el.departmentsQuantity,
      dynamic: el.dynamic,
      name: el.name,
      pointsAverage: el.pointsAverage,
      pointsMax: el.pointsMax,
      id: el.id
    }));
  }, [data?.rating.departmentsMvi]);

  if (loading) return <Loader />;
  if (!!error || !list || list.length === 0) return <PageError />;
  if (!(isObserver || isFRCT)) {
    return <PageError />;
  }

  const _mviTabs = isObserver
    ? MviTabs
    : isFRCT
      ? MviTabs.filter((t) => t.value === ScopeEnum.Frct)
      : isRRCT
        ? MviTabs.filter((t) => t.value === ScopeEnum.Rrct)
        : [];

  return (
    <div>
      <PageHeader
        title={'Аналитика по лучшим показателям'}
        backTo={'/'}
        slotRight={
          <div className={s.MviHeader}>
            {lastUpdateDate && (
              <p className={s.MviHeader__date}>Обновление: {formatDate(lastUpdateDate, DATE_FULL_DAY_SHORT)}</p>
            )}
            <ScopeSwitch scope={ScopeEnum.Frct} scopeTabs={_mviTabs} />
            <ChangeModeBlock className={s.MviHeader__mode} mode={mode} onSetMode={setMode} />
          </div>
        }
      />
      <GaugeCardsList list={list} listType={MviType.Frct} mode={mode} onChangeMode={setMode} />
    </div>
  );
}
