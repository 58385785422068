import React, { useState } from 'react';
import clsx from 'clsx';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { PageError } from 'components/PageError';
import { PageLoader } from 'components/PageLoader';
import { GraphIcon, ListIcon, TabBar } from 'components/TabBar';
import { PageHeader } from 'componentsL/PageHeader';
import { DownloadsBlock } from 'features/DownloadsBlock';
import { FeedbackCard, FeedbackHistory } from 'features/Feedback';
import { FeedbackControls } from 'features/FeedbackControls';
import { FeedbackForm } from 'features/FeedbackForm';
import { IndicatorsTable } from 'features/IndicatorsTable';
import { StatisticsBlock } from 'features/StatisticsBlock';
import { useFeedback } from 'hooks/useFeedback';
import { basename } from 'store/config';
import {
  ExcelDataSourceEnum,
  RatingCommentScopeEnum,
  RatingFeedbackScope,
  RegionIndicatorType,
  useRegionPageQuery
} from 'store/graphql';
import { useProfile } from 'store/profile/useProfile';
import { regionEfficiencyGroups } from 'utils/ratingGroups';
import Card from './Card';
import s from './styled.module.scss';

type ViewMode = 'graph' | 'table';

export function RegionPage() {
  const { regionId } = useParams();
  const [searchParams] = useSearchParams();
  const { allowedToFeedback, allowedToReadFeedback } = useProfile();
  const mode = (searchParams.get('mode') || 'table') as ViewMode;
  const location = useLocation();
  const { loading, error, data, refetch } = useRegionPageQuery({
    variables: {
      ids: [Number(regionId)] as number[]
    }
  });

  const { feedback, openCard, openHistory, openForm, closeAll } = useFeedback();

  const prevDateUpdate = data?.rating.regionRating.regions[0].previousUpdateDate;
  const region = data?.rating.regionRating.regions[0];

  if (loading) return <PageLoader />;
  if (!!error || !region) return <PageError />;

  const indicatorsWithoutGroup = region.indicators.filter(({ groupField }) => !groupField);

  const indicatorsByEpgu = region.indicators.filter(({ groupField }) => groupField === 'epgu');

  const title =
    region.regionName.length <= 32 ? `Оперативный рейтинг РРЦТ ${region.regionName}` : `Оперативный рейтинг РРЦТ`;
  const titleSecondRow = region.regionName.length > 32 ? region.regionName : undefined;

  const hasFeedback = (region.feedback?.length ?? 0) > 0;

  return (
    <div>
      <PageHeader
        backTo={location.key !== 'default' ? 'prevPage' : '/regions'}
        title={title}
        titleSecondRow={titleSecondRow}
        slotRight={
          <div className={clsx(s.Raw, s.Buttons)}>
            <div className={s.Buttons__group}>
              <DownloadsBlock
                links={[
                  {
                    text: 'Методика',
                    href: encodeURI(`${basename}/uploads/Методика_оперативного_рейтинга_РРЦТ_2024.pdf`)
                  }
                ]}
                excel={{
                  dataSource: ExcelDataSourceEnum.Region,
                  entityId: region.regionId.toString(),
                  text: 'Расчет'
                }}
              />
            </div>

            {(allowedToFeedback || allowedToReadFeedback) && (
              <div className={s.Buttons__group}>
                <FeedbackControls
                  allowedToRead={allowedToReadFeedback}
                  allowedToSend={allowedToFeedback}
                  hasFeedback={hasFeedback}
                  openForm={openForm}
                  openHistory={openHistory}
                />
              </div>
            )}

            <div className={s.Buttons__group}>
              <TabBar
                items={[
                  { icon: GraphIcon, to: `/region/${regionId}?mode=graph`, active: mode === 'graph' },
                  { icon: ListIcon, to: `/region/${regionId}?mode=table`, active: mode === 'table' }
                ]}
              />
            </div>
          </div>
        }
      />

      <StatisticsBlock
        efficiencyGroup={region.ratingGroup}
        efficiencyGroupText={regionEfficiencyGroups[region.ratingGroup].text}
        degree={region.degree}
        pointsTotal={region.pointsLast}
        pointsMax={region.pointsMax}
        pointsDynamic={region.dynamic}
        lastPeriodDate={prevDateUpdate}
        indicatorsCount={region.indicators.length}
        rrct={region.rrct[0]?.fullName ?? null}
        representative={region.representatives[0]?.fullName ?? null}
        commentsControl={{
          scope: RatingCommentScopeEnum.Region,
          scopeId: region.regionId,
          scopeName: region.regionName,
          comments: region.recommendations?.entries,
          draft: region.recommendations?.draft,
          refetch: refetch
        }}
      />

      {allowedToReadFeedback && feedback.history && (
        <FeedbackHistory scopeId={region.regionId} data={region.feedback} close={closeAll} selectFeedback={openCard} />
      )}

      {allowedToReadFeedback && feedback.card && feedback.feedbackId && (
        <FeedbackCard
          data={region.feedback?.find((d) => d.feedbackId === feedback.feedbackId)}
          isRegion={true}
          close={closeAll}
          backToHistory={openHistory}
        />
      )}

      {allowedToFeedback && feedback.form && (
        <FeedbackForm
          scopeId={region.regionId}
          isRegion={true}
          feedbackScope={RatingFeedbackScope.Region}
          close={closeAll}
          refetch={refetch}
        />
      )}

      {mode === 'table' && (
        <div className={s.TableWrap}>
          <IndicatorsTable
            rows={region.indicators.map((value) => ({
              indicatorName: value.name,
              value: value.pointsTotal,
              valueDynamic: value.dynamic,
              successMark: value.successMark,
              alertMark: value.alertMark,
              indicatorProgressGroup: value.progressGroup,
              valueMax: value.pointsMax,
              linkTo: `/region/${region?.regionId}/indicator/${value.indicatorId}`,
              availableForDetails: value.availableForDetails,
              hasFeedback: value.haveFeedback,
              hasRecommendations: value.haveRecommendations
            }))}
          />
        </div>
      )}

      {mode === 'graph' && (
        <>
          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(0, 3).map((indicator) => (
              <div className={s.Raw_item} key={indicator.indicatorId}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator as RegionIndicatorType} />
                </div>
              </div>
            ))}
          </div>

          {indicatorsByEpgu.length > 0 && (
            <div key={'ЕПГУ'} className={s.Paper}>
              <h4 className={s.GroupName}>{'ЕПГУ'}</h4>
              <div className={s.Raw}>
                {indicatorsByEpgu.map(({ indicatorId, ...indicator }: any, index) => (
                  <div key={'chart-' + index} className={s.Raw_item}>
                    <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator} />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className={s.Raw}>
            {indicatorsWithoutGroup.slice(3, indicatorsWithoutGroup.length).map((indicator) => (
              <div key={indicator.indicatorId} className={s.Raw_item}>
                <div className={`${s.Paper} ${s.Min_height}`}>
                  <Card linkTo={`indicator/${indicator.indicatorId}`} indicator={indicator as RegionIndicatorType} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
