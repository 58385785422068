import React from 'react';
import clsx from 'clsx';
import { ArrowDown } from 'components/Icon';
import s from './DropdownArrow.module.scss';

export interface DropdownArrowProps {
  isOpen?: boolean;
  dropUp?: boolean;
}

export function DropdownArrow({ isOpen, dropUp }: DropdownArrowProps) {
  const className = clsx(s.DropdownArrow, isOpen && s.DropdownArrow_isOpen, dropUp && s.DropdownArrow_dropUp);
  return <ArrowDown className={className} />;
}
