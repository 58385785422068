import React from 'react';
import clsx from 'clsx';
import s from './Textarea.module.scss';

export interface TextareaProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

export function Textarea({ value, onChange, placeholder, disabled, className }: TextareaProps) {
  return (
    <textarea
      value={value}
      onChange={(e) => !disabled && onChange?.(e.target.value)}
      placeholder={placeholder}
      className={clsx(s.Textarea, disabled && s.Textarea_disabled, !!value && s.Textarea_active, className)}
    />
  );
}
