import React from 'react';
import { Button } from 'components/Button';
import { FileIconSize } from 'components/FileIcon';
import { Modal, ModalError } from 'components/Modal';
import { Overlay } from 'components/Overlay';
import { DepartmentFeedbackType, RegionFeedbackType } from 'store/graphql';
import { FeedbackCardItem } from './_components/FeedbackCardItem';
import { FeedbackFile } from './_components/FeedbackFile';
import { FeedbackFileList } from './_components/FeedbackFileList';

export interface FeedbackCardProps {
  data?: DepartmentFeedbackType | RegionFeedbackType | null;
  isRegion: boolean;
  close: () => void;
  backToHistory: () => void;
}

export function FeedbackCard({ close, backToHistory, isRegion, data }: FeedbackCardProps) {
  const FeedbackDataCurrent = data;
  return (
    <Overlay onClick={close}>
      <Modal title={'Карточка заявки'} onClose={close}>
        {!data || !FeedbackDataCurrent ? (
          <ModalError />
        ) : (
          <React.Fragment>
            {FeedbackDataCurrent.indicatorName && (
              <FeedbackCardItem title="Показатель" content={FeedbackDataCurrent.indicatorName} />
            )}
            <FeedbackCardItem title="Дата обращения" date={FeedbackDataCurrent.publishDate} />
            {!!FeedbackDataCurrent.publisher && (
              <FeedbackCardItem title="Инициатор обращения" content={FeedbackDataCurrent.publisher} />
            )}
            {FeedbackDataCurrent.subject && (
              <FeedbackCardItem title="Тема обращения" content={FeedbackDataCurrent.subject} />
            )}
            {FeedbackDataCurrent.commentary && (
              <FeedbackCardItem title="Комментарий" content={FeedbackDataCurrent.commentary} />
            )}
            {!!FeedbackDataCurrent.attachments.length && (
              <FeedbackFileList title="Приложенные файлы">
                {FeedbackDataCurrent.attachments.map((item, index) => (
                  <FeedbackFile
                    fileName={item.fileName}
                    fileIconSize={FileIconSize.S}
                    id={item.id}
                    key={index}
                    fileFormat={item.fileExtension}
                  />
                ))}
              </FeedbackFileList>
            )}

            <Button style={{ alignSelf: 'flex-end' }} preventScrollReset onClick={backToHistory}>
              Назад
            </Button>
          </React.Fragment>
        )}
      </Modal>
    </Overlay>
  );
}
