import React from 'react';
import { FeedbackCardItem } from '../FeedbackCardItem';
import s from './FeedbackFileList.module.scss';

export function FeedbackFileList({ children, title }: { children: React.ReactNode; title?: string }) {
  return (
    <div className={s.FeedbackFileList}>
      {title && <FeedbackCardItem title={title} />}
      <div className={s.FeedbackFileList__list}>{children}</div>
    </div>
  );
}
