import React from 'react';
import { Tab, Tabs } from 'components/Tabs';
import { FeedbackMode } from '../../_utils/FeedbackMode';

export function ModeTabs({
  mode,
  changeMode,
  indicatorsName
}: {
  mode: FeedbackMode;
  changeMode: (mode: FeedbackMode) => void;
  indicatorsName: string;
}) {
  return (
    <Tabs>
      <Tab onClick={() => changeMode(FeedbackMode.Indicators)} active={mode === FeedbackMode.Indicators}>
        {indicatorsName}
      </Tab>
      <Tab onClick={() => changeMode(FeedbackMode.Suggestions)} active={mode === FeedbackMode.Suggestions}>
        Предложения
      </Tab>
    </Tabs>
  );
}
