import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { RoundIconButton } from 'components/Button';
import { FileIcon, FileIconSize } from 'components/FileIcon';
import { Dismiss, Warning } from 'components/Icon';
import s from './Attachment.module.scss';

export interface AttachmentProps {
  name: string;
  file: File;
  error?: string | null;
  isDuplicate?: boolean;
  onUpload: () => void;
  onError: (error: string) => void;
  onRemove: () => void;
}

export function Attachment({ name, file, error, isDuplicate, onUpload, onError, onRemove }: AttachmentProps) {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    if (!error) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      const handleLoad = () => {
        // успех чтения гарантируется event'ом load
        setContent(fileReader.result as string);
        onUpload();
      };

      const handleError = () => {
        onError('Ошибка чтения файла');
      };

      fileReader.addEventListener('load', handleLoad);
      fileReader.addEventListener('error', handleError);
      return () => {
        fileReader.removeEventListener('load', handleLoad);
        fileReader.removeEventListener('error', handleError);
      };
    }

    // здесь и ниже – мы знаем, что можем использовать любую версию onUpload и onError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, file]);
  return (
    <div className={s.Attachment}>
      <div className={s.Attachment__fileIcon}>
        {!!error ? (
          <Warning className={s.Attachment__warningIcon} />
        ) : (
          <FileIcon fileName={name} fileIconSize={FileIconSize.M} />
        )}

        <RoundIconButton
          className={s.Attachment__removeButton}
          onClick={onRemove}
          icon={Dismiss}
          style={{ width: 24, height: 24 }}
        />
      </div>

      {!!error ? (
        <div className={s.Attachment__error}>{error}</div>
      ) : (
        <React.Fragment>
          <div className={clsx(s.Attachment__name, isDuplicate && s.Attachment__name_isDuplicate)}>{name}</div>
          <div className={clsx(s.Attachment__fileSize, isDuplicate && s.Attachment__fileSize_isDuplicate)}>
            {file.size < 1000000 ? '< 1 MB' : `${Math.round(file.size / 1000000)} MB`}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
