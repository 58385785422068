import React, { useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as Arrow } from './arrow.svg';
import s from './Tooltip.module.scss';

export enum TooltipArrowPosition {
  BottomRight = 'arrowBottomRight',
  BottomCenter = 'arrowBottomCenter',
  BottomLeft = 'arrowBottomLeft'
}

export interface TooltipProps {
  arrowPosition?: TooltipArrowPosition;
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
  tooltipStyle?: React.CSSProperties;
  wrapperClassName?: string;
  hiddenDistance?: number; // Покрывает кейс если tooltip находится на определенном расстояние от верхнего края экрана
}

export function Tooltip({
  arrowPosition = TooltipArrowPosition.BottomCenter,
  content,
  children,
  className,
  tooltipClassName,
  tooltipStyle,
  wrapperClassName,
  hiddenDistance = 60
}: TooltipProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = useCallback(() => {
    if (tooltipRef.current && tooltipRef.current.getBoundingClientRect().top < hiddenDistance) {
      setShowTooltip(false);
    } else {
      setShowTooltip(true);
    }
  }, [hiddenDistance]);
  const handleMouseLeave = useCallback(() => setShowTooltip(false), []);

  return (
    <div className={clsx(s.Tooltip__wrapper, s[`Tooltip__wrapper_${arrowPosition}`], className)} ref={tooltipRef}>
      {showTooltip && (
        <div className={clsx(s.Tooltip, tooltipClassName)} style={tooltipStyle}>
          {content}
          <Arrow className={s.Tooltip__arrow} />
        </div>
      )}

      <div
        className={clsx(s.Tooltip__targetWrapper, wrapperClassName)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </div>
  );
}
