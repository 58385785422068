import React from 'react';
import { DATE_SHORT_YEAR, formatDate } from '@digital-gov/ui-utils';
import s from './FeedbackCardItem.module.scss';

export interface FeedbackCardItemProps {
  title?: string;
  content?: string;
  date?: Date | string;
}

export function FeedbackCardItem({ title, content, date }: FeedbackCardItemProps) {
  return (
    <div className={s.FeedbackCardItem}>
      {title && <div className={s.FeedbackCardItem__title}>{title}</div>}
      {content && <div className={s.FeedbackCardItem__content}>{content}</div>}
      {date && <div className={s.FeedbackCardItem__content}>{formatDate(date, DATE_SHORT_YEAR)}</div>}
    </div>
  );
}
