import React from 'react';
import clsx from 'clsx';
import { Attachment } from 'components/Icon';
import s from './FileInput.module.scss';

export interface FileInputProps {
  id?: string;
  multiple?: boolean;
  accept?: string;
  description?: string;
  disabled?: boolean;
  onChange?: (files: FileList) => void;
  className?: string;
}

export function FileInput({
  id = 'file-input',
  multiple,
  accept = '.doc,.docx,.xls,.xlsx,.pdf,.txt',
  description = 'Загрузите файл в формате DOC, DOCX, PDF, XLS, XLSX, TXT.',
  disabled,
  onChange,
  className
}: FileInputProps) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && !disabled) onChange?.(e.target.files);
  };

  return (
    <div className={clsx(s.FileInput, className)}>
      <label htmlFor={id} className={clsx(s.FileInput__label, disabled && s.FileInput__label_disabled)}>
        <Attachment />
        {'Прикрепить файл'}
      </label>
      <div className={s.FileInput__description}>{description}</div>
      <input
        value={''}
        disabled={disabled}
        onChange={handleChange}
        id={id}
        className={s.FileInput__input}
        type={'file'}
        accept={accept}
        multiple={multiple}
      />
    </div>
  );
}
