import { RatingFeedbackScope } from 'store/graphql';
import { FeedbackMode } from './FeedbackMode';

export const getScope = (scope: RatingFeedbackScope, mode: FeedbackMode) => {
  if (scope === RatingFeedbackScope.Department && mode === FeedbackMode.Indicators)
    return RatingFeedbackScope.DepartmentIndicator;
  if (scope === RatingFeedbackScope.Department && mode === FeedbackMode.Suggestions)
    return RatingFeedbackScope.Department;
  if (scope === RatingFeedbackScope.Region && mode === FeedbackMode.Indicators)
    return RatingFeedbackScope.RegionIndicator;
  return RatingFeedbackScope.Region;
};
